import React, {Component} from "react";

export const MOBILE_SCREEN_WIDTH = 991;
export const TABLET_SCREEN_WIDTH = 1024;
export const DESKTOP_SCREEN_WIDTH = 1025;

class ResponsiveContainer extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isMobile:false,
            isTablet:false,
            isDesktop:false
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', this.throttledHandleWindowResize);
            this.checkWindowWidth();
        }

    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.throttledHandleWindowResize);
        }
    }

    throttledHandleWindowResize = () => {

        this.checkWindowWidth();

    };

    checkWindowWidth() {
        if (typeof window !== 'undefined') {
            this.setState({
                isMobile: window.innerWidth < MOBILE_SCREEN_WIDTH,
                isTablet: window.innerWidth < TABLET_SCREEN_WIDTH,
                isDesktop: window.innerWidth < DESKTOP_SCREEN_WIDTH,
            });
        }
    }
    render() {

        const {children} = this.props;
        const {isMobile} = this.state;

        return (
            <React.Fragment>
                {React.cloneElement(children, { isMobile: isMobile })}
            </React.Fragment>
        )
    }

}

export default ResponsiveContainer;