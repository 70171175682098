import React, {Component} from 'react';
import {graphql} from 'gatsby';
import parse from 'html-react-parser';
import BaseLayout from '../components/Layout/BaseLayout';
import ResponsiveContainer from "../components/ResponsiveContainer";


const RenderTrainings = ({trainingsNumberToShow, trainings}) => {

    const updatedTrainings = [];
    for(let i = 0; i < trainingsNumberToShow; i++) {
        updatedTrainings.push(trainings[i])
    }

    return updatedTrainings.map((training, x) => {
        return (
            <tr key={x}>
                {
                    parse(training.content)
                }
            </tr>
        )
    })
}


const RenderLoadMoreButton = ({loadMoreHandler, trainingsNumberToShow, trainings}) => {
    if(trainingsNumberToShow < trainings.length) {
        return <button onClick={() => loadMoreHandler()}>Meer laden</button>
    }
    return null;
}


class Training extends Component {

    constructor(props){

        super(props);
        this.state= {
            show:false,
            trainingsNumberToShow: 3
        };

        this.loadMoreHandler = this.loadMoreHandler.bind(this);
    }

    componentDidMount(){
        this.setState({
            show:true
        });
    }

    loadMoreHandler = () => {
        const trainings = this.props.data.gravql.page.children;
        const incrementValue = 2;
        if(trainings.length - this.state.trainingsNumberToShow - incrementValue  >= 0) {
            this.setState((prevState) => {
                return { trainingsNumberToShow: prevState.trainingsNumberToShow + incrementValue}
            })
        } else {
            this.setState({trainingsNumberToShow: trainings.length})
        }
    }

    render() {
        const {data, location, isMobile} = this.props;
        let onlineNascholingen = [];
        let regionaleEvents = [];


        data.gravql.page.children.map((training) => {
            if (training.header[0].custom_data == 1) onlineNascholingen.push(training);
            if (training.header[0].custom_data == 2) regionaleEvents.push(training);
        })

        return (
            <BaseLayout show={this.state.show} data={data.gravql} location={location} crumbLabel={data.gravql.page.title}>

                <div className="trainings">
                {
                    parse(data.gravql.page.content)
                }
                    <br/><br/>
                    <h3>Regionale events</h3>
                    <table>
                        <tbody>
                        {regionaleEvents.length >= 1 &&
                        <RenderTrainings trainings={regionaleEvents} trainingsNumberToShow={regionaleEvents.length}/>
                        }
                        {
                            regionaleEvents.length === 0 &&  <p>Momenteel staan er nog geen regionale events gepland, houdt deze pagina in de gaten voor de laatste update.</p>
                        }
                        {/* <RenderTrainings trainings={data.gravql.page.children} trainingsNumberToShow={this.state.trainingsNumberToShow} /> */}
                        </tbody>
                    </table>


                    <br/><br/>
                <h3>Online nascholingen</h3>
                <table>
                    <tbody>
                        <ResponsiveContainer><RenderTrainings trainings={onlineNascholingen} trainingsNumberToShow={onlineNascholingen.length} /></ResponsiveContainer>
                        {/* <RenderTrainings trainings={data.gravql.page.children} trainingsNumberToShow={this.state.trainingsNumberToShow} /> */}
                    </tbody>
                </table>

                {/* LOAD MORE BUTTON */}
                {/* <RenderLoadMoreButton trainings={data.gravql.page.children} trainingsNumberToShow={this.state.trainingsNumberToShow} loadMoreHandler={this.loadMoreHandler}/> */}
                </div>
            </BaseLayout>
        )
    }
}

export default Training;

export const query = graphql`
 query ($slug:String!) {
    gravql {
        pages {
            title
            route
            visible
              breadcrumbs
            header {
                title
                body_classes
                 custom_data
            }
            children {
                title
                route
                header {
                    title
                    body_classes
                }
                children {
                    title
                    route
                }
            }
        }
        page(route: $slug) {
            title      
            content
            visible
            route
            breadcrumbs
            header {
                body_classes
                custom_data
            }
            children {
                title
                route  
                content  
                template 
                id
                header {
                    body_classes
                    custom_data
                    title
                }
                 children {
                    title
                    route
                }         
            }         
            metadata {
                name
                content
            }   
        }
    }
  }
`;
